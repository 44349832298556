<template>
    <section class="l-welcome l-guide">
        <h1 class="l-welcome-ttl">相談を出品したい場合</h1>
        <div class="welcome-article">
            <p>相談を出品したい場合は、講師の応募が必要になります。<br/>
                応募画面から必要情報を入力し、ぜひ講師にご応募ください。</p>
        </div>
        <template> 
            <ul class="flex wrap l-btn-area">
                <li class="l-item-btn">
                    <button class="btn-register btn-radius" @click="_register">会員登録</button>
                </li>
                <li class="l-item-btn">
                    <button class="btn-apply-teacher-info btn-radius teacher" @click="applyTeacher">講師に応募する</button>
                </li>
            </ul>
        </template>
        <div class="txt-center mb-2"><router-link to="/guide">ご利用ガイド（ユーザー向け）</router-link></div>
        <div class="txt-center bm"><router-link to="/guide2">ご利用ガイド（講師向け）</router-link></div>

        <div class="flex lp-section--inner lp-info">
            <div class="figcaption">
                <h2 class="lp-section--title">講師応募が完了したら・・・</h2>
                <p>「出品する」ボタンから出品相談の登録を行えます。必要情報を入力後「確認の画面へ進む」をクリックすると相談のプレビュー画面を表示できます。表示内容に問題がなければ「登録する」から出品登録を行いましょう。
                </p>
                <h3 class="l-ttl">必要事項</h3>
                <ul class="disc">
                    <li>タイトル</li>
                    <li>本文</li>
                    <li>価格</li>
                    <li>サムネイル画像</li>
                    <li>親カテゴリ</li>
                    <li>子カテゴリ</li>
                    <li>相談対象レベル</li>
                    <li>ステータス</li>
                </ul>
            </div>
            <div class="lp-section--img">
                <figure><img src="/img/guide/lesson.png" alt="相談登録"></figure>
            </div>
        </div>
        <RegisterModal :isVisible="this.isVisible" @cancel="actionCancel" />
        <MetaTags/>
    </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "ToSellLesson",
    data() {
        return {
            isVisible:  false
        }
    },
    computed: {
        isMember(){
            return this.user.info;
        },
        ...mapGetters({
            user: "member/getUser"
        }),
    },
    created() {
        if(this.isMember) {
            this.getUserInfo();
        }   
    },
    methods: {
        ...mapActions({
            getUserInfo: "member/getUserInfo",
        }),
        actionCancel() {
            this.isVisible = false;
        },
        _register() {
            if(this.isMember) {
                window.$("#mms-edit-profile").click();
            }else {
                window.$("#mms-sign-up").click();
            }
        },
        applyTeacher() {
            if(this.isMember) {  
                if (this.user.teacher
                    && this.user.teacher.allow_sell == 1
                    && this.user.stripe.is_account_verify == true) {
                    this.$router.push("/my-page/lesson");
                    return;
                } 
                this.$router.push("/apply-sell");
            } 
            this.isVisible = true;
        }
    },
}
</script>